<template>
  <Modal
    as-form
    persistent
    :size="ModalSize.LG"
    :title="$t('add-logo-modal.select-company-logo')"
    :submit-button-text="submitButtonText"
    @submit="onSubmit"
  >
    <template #activator="{ activatorProps }">
      <Btn
        v-bind="activatorProps"
        :color="BtnColor.SECONDARY"
        :size="BtnSize.LG"
        :variant="BtnVariant.OUTLINED"
      >
        {{ $t('post-modal.select-logo') }}
      </Btn>
    </template>
    <template #content>
      <AddLogoContent v-model="selectedLogo" />
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { Btn, BtnColor, BtnSize, BtnVariant } from '~/components/btn';
import { useQuadrantsStore } from '~/store/quadrants-store';
import { AddLogoContent } from '~/pages/my-posts/_components/add-logo-content';
import { Modal, ModalSize } from '~/components/modals/modal';

const model = defineModel<string>({ required: true });

const { t } = useI18n();
const quadrantsStore = useQuadrantsStore();

const initiallySelectedLogo = model.value;
const selectedLogo = ref(initiallySelectedLogo);

const submitButtonText = computed<string>(() => {
  return initiallySelectedLogo && initiallySelectedLogo !== selectedLogo.value
    ? t('common.change')
    : t('common.add');
});

const onSubmit = () => {
  quadrantsStore.selectLogo(selectedLogo.value);
  model.value = selectedLogo.value;
};
</script>
